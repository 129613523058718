// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-template-js": () => import("./../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-club-guide-template-js": () => import("./../src/templates/clubGuideTemplate.js" /* webpackChunkName: "component---src-templates-club-guide-template-js" */),
  "component---src-templates-lesson-template-js": () => import("./../src/templates/lessonTemplate.js" /* webpackChunkName: "component---src-templates-lesson-template-js" */),
  "component---src-templates-fundamentals-js": () => import("./../src/templates/fundamentals.js" /* webpackChunkName: "component---src-templates-fundamentals-js" */),
  "component---src-templates-club-guide-list-template-js": () => import("./../src/templates/clubGuideListTemplate.js" /* webpackChunkName: "component---src-templates-club-guide-list-template-js" */),
  "component---src-templates-register-js": () => import("./../src/templates/register.js" /* webpackChunkName: "component---src-templates-register-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-club-js": () => import("./../src/pages/club.js" /* webpackChunkName: "component---src-pages-club-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lesson-js": () => import("./../src/pages/lesson.js" /* webpackChunkName: "component---src-pages-lesson-js" */)
}

